import React from 'react';

const NotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <h4>Nie znaleziono strony</h4>
    </div>
  );
};

export default NotFound;
