import React from 'react';
import LogoSvg from './assets/autobook_logo2.svg';
import PropTypes from 'prop-types';

const Logo = function ({ width, height, ...pros }) {
  return (
    <>
      <img src={LogoSvg} alt="autobook logo" width={width} height={height} {...pros}/>
    </>
  );
};
Logo.defaultProps = {
  width: 169.564,
  height: 43.512,
};
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
