import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import React from 'react';
import cs from 'classnames';

const DataCard = ({
  title,
  HeaderButtons = null,
  Footer = null,
  fullHeght = false,
  children,
}) => (
  <CCard
    className={cs('shadow-none border', {
      'h-100': fullHeght,
    })}
  >
    <CCardHeader className="bg-light-grey d-flex align-items-center justify-content-between">
      <span className="text-black">{title}</span>
      {HeaderButtons}
    </CCardHeader>
    <CCardBody className="pt-0 d-flex flex-column justify-content-center">
      {children}
    </CCardBody>
    {/* <CCardFooter>Footer.</CCardFooter> */}
    {Footer}
  </CCard>
);

export default DataCard;
