import React, { useEffect } from 'react';
import DataCard from '../../../components/DataCard/DataCard';
import Button from '../../../components/Button/Button';
import { CInput } from '@coreui/react';
import { useMutation, useQuery } from 'react-query';
import MatrixService from '../../../services/MatrixService';
import Loader from '../../../components/Loader/Loader';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import classes from '../Settings.module.css';
import DataRow from '../../../components/DataRow/DataRow';
import styles from '../Matrix.module.css';

const NumberInput = ({ modified, ...props }) => {
  return (
    <div className="position-relative">
      <CInput
        {...props}
        pattern="^[+-]?\d+(,\d{2})?"
        className={styles.numberInput}
      />
      <div className={styles.prc}>
        <span>%</span>
      </div>
    </div>
  );
};

const CorrectionMileageBox = () => {
  const shop_id = useStoreState((state) => state.auth.user.shop_id);

  // --------------------------------------
  // ----------- GET MILEAGE -----------
  // --------------------------------------
  const {
    data: dataMileage,
    isSuccess: isSuccessMileage,
    isLoading: isLoadingMileage,
    refetch: refetchMileage,
  } = useQuery(
    'mileage',
    MatrixService.getAnnualMileageCorrection({ shop_id })
  );

  // --------------------------------------
  // ----------- UPDATE MILEAGE -----------
  // --------------------------------------
  const [mutate, { isLoading: isLoadingUpdateMileage }] = useMutation(
    MatrixService.updateAnnualMileageCorrection({ shop_id }),
    {
      onSuccess: () => {
        toast.success('Pomyślnie zaktualizowane parametry.');
        refetchMileage();
      },
      onError: () => {
        toast.error('Ups... Coś poszło nie tak.');
      },
    }
  );

  // --------------------------------------
  // ----------- HOOK FORM -----------
  // --------------------------------------
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      mileage: [],
    },
  });

  // ----------------------------------------
  // ----------- SET DEFAULT DATA -----------
  // ----------------------------------------
  useEffect(() => {
    if (dataMileage && isSuccessMileage) {
      reset({ mileage: dataMileage.data });
    }
  }, [dataMileage, isSuccessMileage, reset]);

  const onSubmit = (data) => {
    const mileage = [10000, 20000, 30000];
    data.mileage.forEach((item, index) => {
      data.mileage[index] = {
        ...item,
        mileage: mileage[index],
        id: dataMileage.data[index].id,
      };
    });
    mutate({ data: data.mileage });
  };

  // ----------------------------------------
  // ----------- STATUSES -------------------
  // ----------------------------------------
  const isLoading = isLoadingMileage || isLoadingUpdateMileage;
  const isSuccess = isSuccessMileage;

  return (
    <>
      {isLoading && <Loader show={true} />}
      {isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DataCard
            title={'Parametry rocznych limitów przebiegu w km'}
            HeaderButtons={<Button type="submit">Zaktualizuj</Button>}
          >
            {dataMileage.data.map((item, index) => (
              <DataRow
                key={index}
                label={`do ${item.mileage} km`}
                value={
                  <div className={classes.inputContainer}>
                    <NumberInput
                      name={`mileage[${index}].percentFactor`}
                      innerRef={register()}
                    />
                  </div>
                }
              />
            ))}
          </DataCard>
        </form>
      )}
    </>
  );
};

export default CorrectionMileageBox;
