import { createStore } from 'easy-peasy';
import AppStore from './AppStore';
import AuthStore from './AuthStore';
import MatrixStore from './MatrixStore';
import RecommendedProductsStore from './RecommendedProductsStore';

export default createStore({
  auth: AuthStore,
  app: AppStore,
  matrix: MatrixStore,
  recommendedProducts: RecommendedProductsStore,
});
