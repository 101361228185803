import axios from '../axios';
import FAKE_JSON from '../views/Shop/FAKE_RESPONSE.json';
const ShopService = {
  getShops: () => async () => {
    return await axios.get(`/panel/admin/shops`).then((res) => res.data.data);
  },
  getData: (id) => async () => {
    return await axios
      .get(`/panel/admin/shop/${id}`)
      .then((res) => res.data)
      .catch(() => FAKE_JSON);
  },
  getDataPartner: () => async () => {
    return await axios
      .get(`/panel/shop`)
      .then((res) => res.data)
      .catch(() => FAKE_JSON);
  },
  update: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/shop/${id}`, data)
      .then((res) => res.data);
  },
  updatePartner: () => async (data) => {
    return await axios.put(`/panel/shop`, data).then((res) => res.data);
  },
  getPickupPoints: async (id) => {
    return await axios
      .get(`/panel/admin/pickup-points/shop/${id}`)
      .then((res) => res.data.data);
  },
  getPickupPointsPartner: async () => {
    return await axios.get(`/panel/pickup-points`).then((res) => res.data.data);
  },
  getEmployees: async (id) => {
    return await axios
      .get(`/panel/admin/users/shop/${id}`)
      .then((res) => res.data.data);
  },
  getEmployeesPartner: async () => {
    return await axios.get(`/panel/users`).then((res) => res.data.data);
  },
  getPartners: async (id) => {
    return await axios
      .get(`/panel/admin/partner-users/shop/${id}`)
      .then((res) => res.data.data);
  },
  getPartnersPartner: async () => {
    return await axios.get(`/panel/partner-users`).then((res) => res.data.data);
  },
  getIntegrations: async (id) => {
    return await axios
      .get(`/panel/admin/widget-data/shop/${id}`)
      .then((res) => res.data);
  },
  getIntegrationsPartner: async () => {
    return await axios.get(`/panel/widget-data`).then((res) => res.data);
  },
  createPickupPoint: async (data) => {
    return await axios
      .post('/panel/pickup-point', data)
      .then((res) => res.data);
  },
  updatePickupPoint: (id) => async (data) => {
    return await axios
      .put(`/panel/pickup-point/${id}`, data)
      .then((res) => res.data);
  },
  updatePartnerUser: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/user-partner/${id}`, data)
      .then((res) => res.data);
  },
  removePickupPoint: (id) => async () => {
    return await axios
      .delete(`/panel/pickup-point/${id}`)
      .then((res) => res.data);
  },
  updateNote: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/shop-note/${id}`, data)
      .then((res) => res.data);
  },
};

export default ShopService;
