import React, { useState } from 'react';
import styles from './ConfirmRegister.module.scss';
import Header from './components/Header';
import {
  CButton,
  CForm,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
} from '@coreui/react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import PanelUserService from '../../services/PanelUserService';
import { CheckCircle } from 'react-feather';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { TheFooter } from '../../containers';

const ConfirmRegister = () => {
  const history = useHistory();
  const params = useParams();
  const [step, setStep] = useState(0);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [confirmPartner, { isLoading: isLoadingConfirmPartner }] = useMutation(
    PanelUserService.confirmPartner(params.token1, params.token2),
    {
      onSuccess: () => {
        toast.success('Twoje konto w systemie G4 jest aktywne');

        setStep((step) => step + 1);
      },
      onError: (e) => {
        if (
          e?.response?.data?.errors &&
          Array.isArray(e?.response?.data?.errors)
        ) {
          toast.error(e?.response?.data?.errors[0].message);
        } else {
          toast.error('Ups... Coś poszło nie tak');
        }
      },
    }
  );
  const handleClick = () => {
    history.push('/');
  };

  const onSubmit = async (data) => {
    await confirmPartner(data);
  };

  const isLoadingTotal = isLoadingConfirmPartner;
  return (
    <>
      <div className={styles.container}>
        <Loader show={isLoadingTotal} />
        <Header />
        <div className={styles.card}>
          <div className={styles.wrapper}>
            {step === 0 && (
              <>
                <h3>Wprowadź nowe hasło</h3>
                <CForm onSubmit={handleSubmit(onSubmit)}>
                  <CFormGroup>
                    <CLabel htmlFor="raw_password">Wprowadź hasło</CLabel>
                    <CInput
                      type="password"
                      id="raw_password"
                      name="raw_password"
                      innerRef={register}
                    />
                    <CFormText className="mt-3 text-right" color="danger">
                      {errors.raw_password?.message}
                    </CFormText>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="raw_password_repeat">
                      Wprowadź hasło ponownie
                    </CLabel>
                    <CInput
                      type="password"
                      id="raw_password_repeat"
                      name="raw_password_repeat"
                      innerRef={register}
                    />
                    <CFormText className="mt-3 text-right" color="danger">
                      {errors.raw_password_repeat?.message}
                    </CFormText>
                  </CFormGroup>
                  <CFormGroup>
                    <CButton
                      className="w-100 d-block"
                      type="submit"
                      size="xl"
                      color="primary"
                      shape="square"
                    >
                      Potwierdź
                    </CButton>
                  </CFormGroup>
                </CForm>
              </>
            )}
            {step === 1 && (
              <>
                <div className={styles.wrapper}>
                  <h3>Hasło zostało zaktualizowane</h3>
                  <CheckCircle className={styles.CheckCircleIcon} />
                  <p>
                    Twoje konto w systemie G4 jest aktywne, a hasło zostało
                    zaktualizowane. Serdecznie zapraszamy do współpracy!
                  </p>
                  <CButton
                    onClick={handleClick}
                    className="w-100 d-block"
                    type="button"
                    size="xl"
                    color="primary"
                    shape="square"
                  >
                    Przejdź do strony logowania
                  </CButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <TheFooter />
    </>
  );
};

export default ConfirmRegister;
