const SCOPES = {
  PARTNER: 'ROLE_PARTNER',
  MANAGER: 'ROLE_MANAGER',
  TRADER: 'ROLE_TRADER',
  ADMIN: 'ROLE_ADMIN',
  ADMIN_TRADER: 'ROLE_ADMIN_TRADER',
  ADMIN_ANALYST: 'ROLE_ADMIN_ANALYST',
  ADMIN_MANAGER: 'ROLE_ADMIN_MANAGER',
};

const PARTNER = [SCOPES.PARTNER];
const MANAGER = [SCOPES.MANAGER, ...PARTNER];
const TRADER = [SCOPES.TRADER, ...MANAGER];

const ADMIN = [SCOPES.ADMIN];
const ADMIN_MANAGER = [SCOPES.ADMIN_MANAGER, ...ADMIN];
const ADMIN_ANALYST = [SCOPES.ADMIN_ANALYST, ...ADMIN_MANAGER];
const ADMIN_TRADER = [SCOPES.ADMIN_TRADER, ...ADMIN_ANALYST];

const TRADERS = [...TRADER, ...ADMIN_TRADER];
const MANAGERS = [...MANAGER, ...ADMIN_MANAGER];

export const PERMISSIONS = {
  TRADER,
  MANAGER,
  PARTNER,

  ADMIN_TRADER,
  ADMIN_ANALYST,
  ADMIN_MANAGER,
  ADMIN,

  TRADERS,
  MANAGERS,
};

export default SCOPES;
