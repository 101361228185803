import { CCol, CContainer, CRow } from '@coreui/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DataCard from '../../components/DataCard/DataCard';
import ShopsSelect from '../../components/ShopsSelect/ShopsSelect';
import ShopSelectImport from '../../components/ShopsSelect/ShopSelectImport';
import Title from '../../components/Title/Title';
import { useStoreState } from 'easy-peasy';

const MatrixSelectShop = () => {
  const history = useHistory();

  const shop = useStoreState((state) => state.auth.user?.shop_symbol);

  // REDIRECT IF PARTNER
  useEffect(() => {
    shop && history.push(`/${shop}/partnerMatrix`);
  }, [shop, history]);

  return (
    <>
      <Breadcrumb path={[{ label: 'Panel administracyjny', url: '/' }]} />
      <CContainer fluid>
        <main className="c-main">
          <Title>Wybierz Partnera</Title>
          <CRow>
            <CCol sm="12" xl="12">
              {!shop &&
                history.location.pathname === '/product-acceptance-list' && (
                  <DataCard title="Ustawienia Importu">
                    <CRow className="mt-4">
                      <div className="px-4 flex-fill">
                        <ShopSelectImport
                          onChange={({ symbol }) => {
                            history.push(`/product-acceptance-list/${symbol}`);
                          }}
                        />
                      </div>
                    </CRow>
                  </DataCard>
                )}
              {!shop && history.location.pathname === '/matrix' && (
                <DataCard title="Matryca RV/BB">
                  <CRow className="mt-4">
                    <div className="px-4 flex-fill">
                      <ShopsSelect
                        onChange={({ symbol }) => {
                          history.push(`/${symbol}/matrix`);
                        }}
                      />
                    </div>
                  </CRow>
                </DataCard>
              )}
            </CCol>
          </CRow>
        </main>
      </CContainer>
    </>
  );
};

export default MatrixSelectShop;
