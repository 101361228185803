import React from 'react';
import Feed from './Feed';
import ProductImportConfigBox from './ProductImportConfigBox';
import { CAlert } from '@coreui/react';
import Title from '../../../components/Title/Title';
import { useStoreState } from 'easy-peasy';

const FeedImporter = ({ shop, shopId }) => {
  const role = useStoreState((state) => state.auth.role);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <Title className="mb-0 mt-4">
          Integracja bazy produktowej - ustawienia feed produktowego
        </Title>
      </div>
      {!shop || !shopId ? (
        <CAlert color="danger" className={'mt-3'}>
          {role === 'ROLE_ADMIN' && (
            <div>
              Aby uruchomić integrację feed produktowego dodaj w panelu
              przynajmniej jeden produkt główny
            </div>
          )}
          {role !== 'ROLE_ADMIN' && (
            <div>
              Aby uruchomić integrację feed produktowego skontaktuj się z
              Administratorem systemu.
            </div>
          )}
        </CAlert>
      ) : (
        <>
          <Feed shopId={shopId} shop={shop} />
          <ProductImportConfigBox shop={shop} />
        </>
      )}
    </>
  );
};

export default FeedImporter;
