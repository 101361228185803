import axios from '../axios';
import { parseObjectToUrlQuery } from '../helpers';

const MatrixService = {
  getData:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/admin/matrix?${_filters}`)
        .then((res) => res.data.data);
    },
  getPartnerData:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/matrix?${_filters}`)
        .then((res) => res.data.data);
    },

  getBrands:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/admin/matrix/default?${_filters}`)
        .then((res) => res.data.data);
    },
  getPanelBrands:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/matrix/default?${_filters}`)
        .then((res) => res.data.data);
    },
  updateMatrix: () => async (data) =>
    await axios.put(`/panel/admin/matrix`, data).then((res) => res.data),
  updatePartnerMatrix: () => async (data) =>
    await axios.put(`/panel/matrix`, data).then((res) => res.data),
  getCorrectionPrice:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/admin/matrix/correction/price?${_filters}`)
        .then((res) => res.data.data);
    },
  getPartnerCorrectionPrice:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/matrix/correction/price?${_filters}`)
        .then((res) => res.data.data);
    },

  updateCorretionPrice: () => async (data) =>
    await axios
      .put(`/panel/admin/matrix/correction/price`, data)
      .then((res) => res.data),
  updatePartnerCorretionPrice: () => async (data) =>
    await axios
      .put(`/panel/matrix/correction/price`, data)
      .then((res) => res.data),

  getCorrectionState:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/admin/matrix/correction/state?${_filters}`)
        .then((res) => res.data.data);
    },
  getPartnerCorrectionState:
    (filters = {}) =>
    async () => {
      const _filters = parseObjectToUrlQuery({
        records_per_page: 10000000,
        ...filters,
      });

      return await axios
        .get(`/panel/matrix/correction/state?${_filters}`)
        .then((res) => res.data.data);
    },
  updateCorretionState: () => async (data) =>
    await axios
      .put(`/panel/admin/matrix/correction/state`, data)
      .then((res) => res.data),
  updatePartnerCorretionState: () => async (data) =>
    await axios
      .put(`/panel/matrix/correction/state`, data)
      .then((res) => res.data),
  getAnnualMileageCorrection: (params) => () => {
    const query = parseObjectToUrlQuery(params);
    return axios
      .get(`/init/annual-mileage-corrections?${query}`)
      .then((res) => res.data);
  },
  // update has to be post!
  updateAnnualMileageCorrection: (params) => (data) => {
    const query = parseObjectToUrlQuery(params);
    return axios
      .post(`/panel/admin/annual-mileage-corrections?${query}`, data)
      .then((res) => res.data);
  },
};

export default MatrixService;
