import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import Loader from './components/Loader/Loader';
import AuthService from './services/AuthService';

const AuthProvider = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const auth = useStoreState((state) => state.auth);
  const setUser = useStoreActions((actions) => actions.auth.setUser);
  const clearUser = useStoreActions((actions) => actions.auth.clearUser);

  const checkUser = async () => {
    try {
      const _user = await AuthService.CheckToken();
      if (_user) {
        setUser({
          token: auth.token,
          user: _user,
        });
        setAuthChecked(true);
      } else {
        throw new Error('Unauthorized');
      }
    } catch (e) {
      clearUser();
      setAuthChecked(true);
    }
  };

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line
  }, []);

  if (authChecked) {
    return children;
  }
  return <Loader show />;
};

export default AuthProvider;
