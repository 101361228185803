import CIcon from '@coreui/icons-react';
import { CInput, CSwitch } from '@coreui/react';
import React, { useCallback, useState } from 'react';
import styles from '../Matrix.module.css';

const CHANGED_BG = '#f2f2f2';

const NumberInput = ({ modified, ...props }) => {
  return (
    <div className="position-relative">
      <CInput
        {...props}
        pattern="^[+-]?\d+(,\d{2})?"
        maxLength="3"
        className={styles.numberInput}
        style={{
          background: !modified ? 'white' : CHANGED_BG,
        }}
      />
      <div className={styles.prc}>
        <span>%</span>
      </div>
    </div>
  );
};
const NumberInputDecimals = ({ modified, ...props }) => {
  return (
    <div className="position-relative">
      <CInput
        {...props}
        pattern="^\d+((,|.)\d+)?"
        maxLength="5"
        className={styles.numberInput}
        style={{
          background: !modified ? 'white' : CHANGED_BG,
        }}
      />
      <div className={styles.prc}>
        <span>%</span>
      </div>
    </div>
  );
};

const MatrixForm = ({ data, register, admin }) => {
  const [visibleIndexes, setVisibleIndexes] = useState([]);

  const toggleIndex = useCallback(
    (index) => {
      if (visibleIndexes.indexOf(index) === -1) {
        setVisibleIndexes([...visibleIndexes, index]);
      } else {
        setVisibleIndexes(visibleIndexes.filter((i) => i !== index));
      }
    },
    [visibleIndexes]
  );
  const handleSwitchChange = () => {
    const x = window.scrollX,
      y = window.scrollY;
    document.getElementById('matrix-submit').focus();
    window.scrollTo(x, y);
  };
  return (
    <div className="overflow-auto">
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.tdName}></th>

            {admin && <th className={styles.td}></th>}
            <th className={styles.td} colSpan={5}>
              <div className={styles.tdGray}>BB (%)</div>
            </th>

            {admin && (
              <th className={styles.td} colSpan={5}>
                <div className={styles.tdGray}>RV (%)</div>
              </th>
            )}
          </tr>
          <tr>
            <th className={styles.tdName}>Marka produktu</th>

            {admin && <th className={styles.td}>BB/RV</th>}

            <th className={styles.td}>1 MC</th>
            <th className={styles.td}>3 MC</th>
            <th className={styles.td}>6 MC</th>
            <th className={styles.td}>12 MC</th>
            <th className={styles.td}>24 MC</th>
            {admin && (
              <>
                <th className={styles.td}>1 MC</th>
                <th className={styles.td}>3 MC</th>
                <th className={styles.td}>6 MC</th>
                <th className={styles.td}>12 MC</th>
                <th className={styles.td}>24 MC</th>
              </>
            )}
            <th className={styles.td}>Rabat - nowe</th>
            <th className={styles.td}>Rabat - używane</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data)
            .sort((a, b) => {
              return a[1].name > b[1].name ? 1 : -1;
            })
            .map(
              ([
                id,
                {
                  name,
                  categories,
                  bb1_modified,
                  bb3_modified,
                  bb6_modified,
                  bb12_modified,
                  bb24_modified,
                  rv1_modified,
                  rv3_modified,
                  rv6_modified,
                  rv12_modified,
                  rv24_modified,
                  rv_mode_modified,
                  discountNew_modified,
                  discountUsed_modified,
                },
              ]) => (
                <tr key={id}>
                  <td colSpan={admin ? 14 : 8}>
                    <table className={styles['w-100']}>
                      <tbody>
                        <tr colSpan={admin ? 14 : 8}>
                          <td className={`${styles.tdName} ${styles.tdGray}`}>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="">
                                <strong className="text-uppercase">
                                  {name}
                                </strong>
                                <input
                                  name={`${id}.brand_id`}
                                  ref={register}
                                  type="hidden"
                                />
                                <input
                                  name={`${id}.brand_name`}
                                  ref={register}
                                  type="hidden"
                                />
                                <input
                                  name={`${id}.shop_id`}
                                  ref={register}
                                  type="hidden"
                                />
                              </div>
                              {Object.entries(categories).length > 0 && (
                                <div className="cursor-pointer">
                                  {visibleIndexes.indexOf(id) ? (
                                    <CIcon
                                      name="cis-plus-square"
                                      width={20}
                                      onClick={() => toggleIndex(id)}
                                    />
                                  ) : (
                                    <CIcon
                                      name="cis-minus-square"
                                      width={20}
                                      onClick={() => toggleIndex(id)}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </td>

                          {admin && (
                            <td className={styles.td}>
                              <CSwitch
                                color="primary"
                                name={`${id}.rv_mode`}
                                innerRef={register}
                                onChange={handleSwitchChange}
                                className={
                                  !!rv_mode_modified ? 'matrix modified' : ''
                                }
                              />
                            </td>
                          )}

                          <td className={styles.td}>
                            <NumberInput
                              name={`${id}.bb1`}
                              innerRef={register}
                              modified={bb1_modified}
                            />
                          </td>

                          <td className={styles.td}>
                            <NumberInput
                              name={`${id}.bb3`}
                              innerRef={register}
                              modified={bb3_modified}
                            />
                          </td>

                          <td className={styles.td}>
                            <NumberInput
                              name={`${id}.bb6`}
                              innerRef={register}
                              modified={bb6_modified}
                            />
                          </td>

                          <td className={styles.td}>
                            <NumberInput
                              name={`${id}.bb12`}
                              innerRef={register}
                              modified={bb12_modified}
                            />
                          </td>

                          <td className={styles.td}>
                            <NumberInput
                              name={`${id}.bb24`}
                              innerRef={register}
                              modified={bb24_modified}
                            />
                          </td>

                          {admin && (
                            <>
                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.rv1`}
                                  innerRef={register}
                                  modified={rv1_modified}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.rv3`}
                                  innerRef={register}
                                  modified={rv3_modified}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.rv6`}
                                  innerRef={register}
                                  modified={rv6_modified}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.rv12`}
                                  innerRef={register}
                                  modified={rv12_modified}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.rv24`}
                                  innerRef={register}
                                  modified={rv24_modified}
                                />
                              </td>
                            </>
                          )}

                          <td className={styles.td}>
                            <NumberInputDecimals
                              name={`${id}.discountNew`}
                              innerRef={register}
                              disabled={!admin}
                              modified={discountNew_modified}
                            />
                          </td>

                          <td className={styles.td}>
                            <NumberInputDecimals
                              disabled={!admin}
                              name={`${id}.discountUsed`}
                              innerRef={register}
                              modified={discountUsed_modified}
                            />
                          </td>
                        </tr>
                        {Object.entries(categories).map(
                          ([categoryId, { name }]) => (
                            <tr
                              key={`${categoryId}`}
                              style={{
                                display:
                                  visibleIndexes.indexOf(id) === -1
                                    ? 'none'
                                    : 'table-row',
                              }}
                            >
                              <td className={styles.tdGray}>
                                <div className="pl-2">{name}</div>
                                <input
                                  name={`${id}.categories.${categoryId}.brand_id`}
                                  ref={register}
                                  type="hidden"
                                />
                                <input
                                  name={`${id}.categories.${categoryId}.category_id`}
                                  ref={register}
                                  type="hidden"
                                />
                                <input
                                  name={`${id}.categories.${categoryId}.shop_id`}
                                  ref={register}
                                  type="hidden"
                                />
                              </td>

                              {admin && (
                                <td className={styles.td}>
                                  <CSwitch
                                    name={`${id}.categories.${categoryId}.rv_mode`}
                                    color="primary"
                                    innerRef={register}
                                    onChange={handleSwitchChange}
                                  />
                                </td>
                              )}

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.categories.${categoryId}.bb1`}
                                  innerRef={register}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.categories.${categoryId}.bb3`}
                                  innerRef={register}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.categories.${categoryId}.bb6`}
                                  innerRef={register}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.categories.${categoryId}.bb12`}
                                  innerRef={register}
                                />
                              </td>

                              <td className={styles.td}>
                                <NumberInput
                                  name={`${id}.categories.${categoryId}.bb24`}
                                  innerRef={register}
                                />
                              </td>

                              {admin && (
                                <>
                                  <td className={styles.td}>
                                    <NumberInput
                                      name={`${id}.categories.${categoryId}.rv1`}
                                      innerRef={register}
                                    />
                                  </td>

                                  <td className={styles.td}>
                                    <NumberInput
                                      name={`${id}.categories.${categoryId}.rv3`}
                                      innerRef={register}
                                    />
                                  </td>

                                  <td className={styles.td}>
                                    <NumberInput
                                      name={`${id}.categories.${categoryId}.rv6`}
                                      innerRef={register}
                                    />
                                  </td>

                                  <td className={styles.td}>
                                    <NumberInput
                                      name={`${id}.categories.${categoryId}.rv12`}
                                      innerRef={register}
                                    />
                                  </td>

                                  <td className={styles.td}>
                                    <NumberInput
                                      name={`${id}.categories.${categoryId}.rv24`}
                                      innerRef={register}
                                    />
                                  </td>
                                </>
                              )}
                              <td className={styles.td}>
                                <NumberInputDecimals
                                  disabled={!admin}
                                  name={`${id}.categories.${categoryId}.discountNew`}
                                  innerRef={register}
                                />
                              </td>
                              <td className={styles.td}>
                                <NumberInputDecimals
                                  disabled={!admin}
                                  name={`${id}.categories.${categoryId}.discountUsed`}
                                  innerRef={register}
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

MatrixForm.defaultProps = {
  admin: true,
};
export default MatrixForm;
