import { CCol, CInput, CRow, CSpinner } from '@coreui/react';
import cs from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import Button from '../../../components/Button/Button';
import DataCard from '../../../components/DataCard/DataCard';
import DataRow from '../../../components/DataRow/DataRow';
import MatrixService from '../../../services/MatrixService';
import styles from '../Matrix.module.css';
import { toast } from 'react-toastify';
const NumberInput = ({ modified, ...props }) => {
  return (
    <div className="position-relative">
      <CInput
        {...props}
        pattern="^[+-]?\d+(,\d{2})?"
        className={styles.numberInput}
      />
      <div className={styles.prc}>
        <span>%</span>
      </div>
    </div>
  );
};
const CorrectionStateBox = () => {
  const { shop } = useParams();

  const { register, reset, handleSubmit } = useForm();

  const [update, { isLoading: isDataFetching }] = useMutation(
    MatrixService.updateCorretionState(),
    {
      onError: (e) => {
        if (e?.response?.data?.errors?.length > 0) {
          // eslint-disable-next-line array-callback-return
          e.response.data.errors.map(({ message }) => {
            toast.error(message);
          });
        }
      },
    }
  );

  const {
    isLoading: isDataLoading,
    data,
    refetch,
  } = useQuery(
    `correction-state-${shop}`,
    MatrixService.getCorrectionState({
      shop_symbol: shop,
    }),
    {
      onSuccess: (data) => {
        const parsedValues = data.reduce((a, b) => {
          return {
            ...a,
            ...{
              [b.id]: {
                new_product: b.new_product,
                used_product: b.used_product,
              },
            },
          };
        }, {});

        reset(parsedValues);
      },
    }
  );
  const onSubmit = async (values) => {
    const rows = Object.entries(values).map(([id, value]) => ({
      ...value,
      id,
    }));

    await update({ rows });
    await refetch();
  };

  const isLoading = !!isDataFetching || !!isDataLoading;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      <DataCard
        title={'Korekta wartości odkupu dla przedziałów cenowych'}
        HeaderButtons={
          <div>
            <Button type="submit">Zapisz</Button>
          </div>
        }
      >
        <CRow>
          <CCol sm="12" xl="12">
            {!!isLoading && (
              <div
                className={cs(
                  'd-flex justify-content-center align-items-center l-0 t-0 w-100 h-100 position-absolute',
                  styles.loaderContainer
                )}
              >
                <CSpinner
                  color="primary"
                  style={{ width: '4rem', height: '4rem' }}
                />
              </div>
            )}
            {!!data && data.length > 0 && (
              <>
                <DataRow
                  label="Nowe"
                  value={
                    <NumberInput
                      name={`${data[0].id}.new_product`}
                      innerRef={register}
                    />
                  }
                />
                <DataRow
                  label="Używane"
                  value={
                    <NumberInput
                      name={`${data[0].id}.used_product`}
                      innerRef={register}
                    />
                  }
                />
              </>
            )}
          </CCol>
        </CRow>
      </DataCard>
    </form>
  );
};

export default CorrectionStateBox;
