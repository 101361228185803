import React from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import BrandsService from '../../services/BrandsService';

const MultiBrandSelect = ({ onChange, value, ...props }) => {
  const { data, isFetching } = useQuery(`brand`, BrandsService.getBrands());

  const parsedValues = useMemo(() => {
    if (!data) return [];
    return data.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [data]);

  return (
    <ReactSelect
      className="flex-fill"
      options={parsedValues}
      isMulti={true}
      name="brandIds"
      isLoading={isFetching}
      onChange={(parsedValues) =>
        onChange(parsedValues?.map((option) => option.value))
      }
      value={parsedValues.filter((option) => value?.includes(option.value))}
      styles={{
        menuList: (provided) => ({
          ...provided,
          textAlign: 'left',
        }),
      }}
      {...props}
    />
  );
};

export default MultiBrandSelect;
