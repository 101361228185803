import { PERMISSIONS } from '../scopes';

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/',
    icon: 'cil-speedometer',
    // scopes: [...PERMISSIONS.PARTNER],
    scopes: [...PERMISSIONS.TRADER],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Produkty',
    route: '/products',
    icon: 'cil-house',
    scopes: [...PERMISSIONS.TRADERS],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Produkty główne',
        to: '/products/main',
        scopes: [...PERMISSIONS.TRADERS],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Produkty dodatkowe',
        to: '/products/additional',
        scopes: [...PERMISSIONS.TRADERS],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Akcesoria',
        to: '/products/accessories',
        scopes: [...PERMISSIONS.TRADERS],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Usługi',
        to: '/products/services',
        scopes: [...PERMISSIONS.MANAGER, ...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Kategorie',
        to: '/categories',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Marki',
        to: '/brands',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Import',
        to: '/product-acceptance-list',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Ustawienia',
        to: '/matrix',
        scopes: [...PERMISSIONS.ADMIN_MANAGER, ...PERMISSIONS.MANAGER],
      },
      // {
      //   _tag: "CSidebarNavItem",
      //   name: "Ustawienia",
      //   to: "/partner/matrix",
      //   scopes: [...PERMISSIONS.MANAGER],
      // },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Aktywa i usługi',
    to: '/assets&services',
    icon: 'cil-library-building',
    scopes: [...PERMISSIONS.ADMIN_TRADER],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Aktywa',
        to: '/assets&services/assets',
        scopes: [...PERMISSIONS.TRADERS],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Usługi',
        to: '/assets&services/services',
        scopes: [...PERMISSIONS.TRADERS],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Rezerwacje',
    to: '/reservations',
    icon: 'cil-basket',
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },

  {
    _tag: 'CSidebarNavDropdown',
    name: 'Wnioski',
    route: '/applications',
    icon: 'cil-envelope-open',
    scopes: [...PERMISSIONS.ADMIN_TRADER],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Wnioski aktywne',
        to: '/applications/active',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Wnioski rozpatrzone',
        to: '/applications/examined',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Wnioski z błędem BIK',
        to: '/applications/error',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Wnioski wycofane',
        to: '/applications/archived',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zamówienia',
    to: '/orders',
    icon: 'cil-cart',
    scopes: [...PERMISSIONS.TRADER],
  },

  {
    _tag: 'CSidebarNavDropdown',
    name: 'Zamówienia',
    route: '/orders',
    icon: 'cil-envelope-open',
    scopes: [...PERMISSIONS.ADMIN_TRADER],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Zamówienia aktywne',
        to: '/orders',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Zamówienia wycofane',
        to: '/orders/archived',
        scopes: [...PERMISSIONS.ADMIN_TRADER],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Umowy',
    to: '/agreements',
    icon: 'cil-pencil',
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Klienci',
    to: '/customers',
    icon: 'cil-people',
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Odkupy',
    to: '/repurchase',
    icon: 'cil-cash',
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Płatności',
    to: '/payments',
    icon: 'cib-cashapp',
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Partnerzy',
    to: '/shops',
    icon: 'cis-people',
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },

  {
    _tag: 'CSidebarNavDropdown',
    name: 'Dane Partnera',
    to: '/partner-data',
    icon: 'cis-people',
    scopes: [...PERMISSIONS.TRADER],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Ustawienia',
        to: '/partner-data',
        icon: 'cil-settings',
        scopes: [...PERMISSIONS.TRADER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pracownicy',
        to: '/users',
        icon: 'cis-people',
        scopes: [...PERMISSIONS.MANAGER],
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Ustawienia',
    to: '/settings',
    icon: 'cil-settings',
    scopes: [...PERMISSIONS.ADMIN_MANAGER],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Ustawienia',
        to: '/settings',
        icon: 'cil-settings',
        scopes: [...PERMISSIONS.ADMIN_MANAGER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pracownicy',
        to: '/users',
        icon: 'cis-people',
        scopes: [...PERMISSIONS.ADMIN_MANAGER],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'E-commerce',
    to: '/ecommerce',
    icon: 'cil-cart',
    scopes: [...PERMISSIONS.ADMIN_MANAGER],
  },
];
