import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import React from 'react';
import { TheContent, TheHeader, TheSidebar } from './index';

const TheLayout = () => {
  const darkMode = useStoreState((state) => state.app.darkMode);
  const classes = classNames(
    'c-app c-default-layout',
    darkMode && 'c-dark-theme'
  );

  return (
    <div className={classes}>
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
