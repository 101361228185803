import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CRow,
} from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../components/Logo/Logo';
import styles from '../../views/Login/Login.module.css';
import PanelUserService from '../../services/PanelUserService';
import Loader from '../../components/Loader/Loader';

const RequestNewPassword = function () {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [sendResetLink, { isLoading: isSendingLink }] = useMutation(
    PanelUserService.requestNewPassword,
    {
      onSuccess: () => {
        toast.success(
          'Link do zresetowania hasła został wysłany na podany adres email'
        );
      },
      onError: (data) => {
        if (data?.response?.data?.data) {
          toast.error(data?.response?.data?.data);
        } else {
          toast.error('Ups... Coś poszło nie tak.');
        }
      },
    }
  );

  const onSubmit = async (data) => {
    sendResetLink(data);
  };

  return (
    <CRow
      className={`${styles.container} align-items-center flex-fill vh-100 text-center`}
    >
      {isSendingLink && <Loader show={true} />}
      <CCol className={styles.loginContainer}>
        <CCard className={styles.card}>
          <CCardHeader className={styles.header}>
            <Logo width={390} height={70}/>
          </CCardHeader>
          <CCardBody className={styles.formContainer}>
            <CForm onSubmit={handleSubmit(onSubmit)}>
              <CFormGroup>
                <CLabel htmlFor="email">
                  Podaj swój adres email używany do logowania
                </CLabel>
                <CInputGroup>
                  <CInputGroupPrepend>
                    <CInputGroupText>
                      <CIcon name="cil-user" />
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput
                    id="email"
                    name="email"
                    placeholder="Adres email"
                    innerRef={register}
                  />
                </CInputGroup>
              </CFormGroup>
              <CFormGroup className={`${styles.formFooter} form-actions`}>
                <CButton type="submit" size="xl" color="primary" shape="square">
                  Resetuj hasło
                </CButton>
                <Link to={'/login'} className={'mt-3'}>
                  Wróć na stronę logowania
                </Link>
              </CFormGroup>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default RequestNewPassword;
